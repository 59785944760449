<template>
  <div>
    <guest-navbar />
    <guest-slider>
      <template slot="content">
        <div class="mb-4">
          <h5>Create an account</h5>
          <hr />
          <form @submit.prevent="register" id="register-form">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      placeholder="e.g John"
                      name="first_name"
                      class="form-control"
                      required
                      v-model="user.first_name"
                    />
                  </div>
                </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Surname</label>
                      <input
                        type="text"
                        placeholder="e.g Kamau"
                        name="surname"
                        class="form-control"
                        v-model="user.surname"
                      />
                    </div>
                  </div>

              </div>
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      placeholder="e.g Doe"
                      name="last_name"
                      class="form-control"
                      required
                      v-model="user.last_name"
                    />
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      class="form-control"
                      required
                      v-model="user.email"
                    />
                  </div>
               </div>
             </div>
        <div class="row">
            <div class="col-md-12">

              <div class="form-group">
                <label>Referral Code(Optional)</label>
                <input
                  type="text"
                  placeholder="Referral Code e.g X893984"
                  name="Referral Code"
                  class="form-control"
                  v-model="user.referral_code"
                />
              </div>
            </div>
          <div class="col-md-7">
             <div class="form-group">
              <label>Password</label>
              <input
                type="password"
                placeholder="Password"
                name="password"
                class="form-control"
                required
                v-model="user.password"
              />
            </div>
          </div>
        </div>
        
            <div class="form-group text-right">
              <button class="btn btn-secondary">Create Account</button>
            </div>
          </form>
          <p>
            <router-link :to="{ name: 'login' }"
              >Have an account? Sign In.</router-link
            >
          </p>
        </div>
      </template>
    </guest-slider>
    <guest-footer />
    <guest-quote />
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {},
    };
  },

  methods: {
    register() {
      this.$loader.start();
      this.$axios
        .post("/api/v1/register", this.user)
        .then((response) => {
          this.$auth.setToken(response.data.access_token);
          window.location.href = this.$router.resolve({
            name: "dashboard",
          }).href;
        })
        .catch((error) => {
          this.$http(error.response, "register-form");
          this.$loader.stop();
        });
    },
  },
};
</script>
